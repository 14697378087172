import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../redux/store";

const PrivateRoute: React.FC = () => {
  const isLogin = useSelector((state: RootState) => state.user.isLogin);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if (!isLogin) {
      setShowLogin(true);
      const timer = setTimeout(() => {
        setShowLogin(false);
      }, 9000); // 5-second delay before redirection

      return () => clearTimeout(timer);
    }
  }, [isLogin]);

  if (!isLogin && !showLogin) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
