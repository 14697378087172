import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { url } from "../App"; // Adjust the import path as needed
import { userActions } from "../redux/slice/user"; // Adjust the import path as needed
import { AppDispatch, RootState } from "../redux/store";
import { useSelector } from "react-redux";

const useAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector((state: RootState) => state.user.isLogin);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get(`${url}/users/auth/check`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          dispatch(userActions.getUser(response.data.user));
          dispatch(userActions.loginHandler(true));
        } else {
          throw new Error("User is not authenticated");
        }
      } catch (error) {
        dispatch(userActions.loginHandler(false));
        console.error("User is not authenticated", error);
      }
    };

    checkAuth();
  }, [dispatch]);
  return { isAuthenticated };
};

export default useAuth;
